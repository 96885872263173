import LoginComponent from '../components/LoginComponent'
import {connect} from 'react-redux'
import {handleLogin, checkLogin} from '../actions'

const mapStateToProps = (state, ownProps) => {
    return {
        apikey: state.user.apikey,
        token: state.user.token,
        login: state.login
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogin: (Account, Password)=>{
            dispatch(handleLogin(Account, Password))
        },
        checkLogin: () => {
            dispatch(checkLogin())
        }
    }
}

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent)

export default LoginContainer