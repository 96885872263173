import { sha256 } from "js-sha256";
const key = "c823de931a8a3094b6886ee9c756b08648c99f3b536e7da1b77c2a320e73e05d";

export const getApikey = () => {
  let dateObj = new Date();
  let month = dateObj.getMonth() + 1;
  let date = dateObj.getDate();
  let year = dateObj.getFullYear();
  month = month.toString().padStart(2, 0);
  date = date.toString().padStart(2, 0);
  let hour = dateObj.getHours();
  let minute = dateObj.getMinutes();
  hour = hour.toString().padStart(2, 0);
  minute = minute.toString().padStart(2, 0);
  let apikey = month + date + year + key + hour + minute;
  let sha256key = sha256(apikey);
  return sha256key;
};
export const handleLogin = (Account, Password) => {
  return (dispatch, getState) => {
    let apikey = getApikey();
    fetch("https://i-daka.com:5425/api/TvBoxWeb/Login", {
      method: "POST",
      body: JSON.stringify({
        Account: Account,
        Password: Password,
      }),
      headers: {
        "content-type": "application/json",
        apikey: apikey,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result) {
          dispatch(receiveJwtToken(json.JwtToken, apikey));
          dispatch(loginSuccess());
          localStorage.setItem("jwt_token", json.JwtToken);
        } else {
          dispatch(loginFail(json.message));
        }
      })
      .catch((err) => {
        dispatch(loginFail());
      });
  };
};

export const checkLogin = () => {
  return (dispatch, getState) => {
    if (localStorage.getItem("jwt_token")) {
      let token = localStorage.getItem("jwt_token");
      let apikey = getApikey();
      dispatch(receiveJwtToken(token, apikey));
      dispatch(loginSuccess());
    }
  };
};

export const receiveJwtToken = (token, apikey) => {
  return {
    type: "RECEIVE_JWTTOKEN",
    token,
    apikey,
  };
};

export const loginSuccess = () => {
  return {
    type: "LOGIN_SUCCESS",
    message: "登入成功",
  };
};

export const loginFail = (message) => {
  return {
    type: "LOGIN_FAIL",
    message: message,
  };
};
export const testJwt = (JwtToken, apikey) => {
  fetch("https://i-daka.com:5425/api/TvBoxWeb/WhoIam", {
    method: "GET",
    headers: {
      "content-type": "application/json",
      apikey: apikey,
      authorization: JwtToken,
    },
  });
};

export const queryInformation = () => {
  return (dispatch, getState) => {
    console.log(getState().user.apikey, getState().user.token);

    fetch("https://i-daka.com:5425/api/TvBoxWeb/WorkAreaInfo", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        apikey: getState().user.apikey,
        authorization: getState().user.token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);

        dispatch(receiveInformation(json.data));
      });
  };
};

export const queryInformation_test = () => {
  let data = {
    company: "璽樂科技",
    members: [
      {
        img: "../identicon.png",
        Name: "大明",
        Phone: "092222123",
      },
      {
        Name: "大明",
        Phone: "092222123",
      },
      {
        Name: "大明",
        Phone: "092222123",
      },
      {
        img: "../identicon.png",
        Name: "大明",
        Phone: "092222123",
      },
      {
        Name: "大明",
        Phone: "092222123",
      },
      {
        Name: "大明",
        Phone: "092222123",
      },
    ],
    location: "第二管道",
  };
  return (dispatch, getState) => {
    dispatch(receiveInformation(data));
  };
};
export const receiveInformation = (data) => {
  console.log("receiveInformation", data);

  let areaId = [];
  let dataInPlace = [];
  let index;
  for (let i in data) {
    if (areaId.indexOf(data[i].AreaID) < 0) {
      areaId.push(data[i].AreaID);
      dataInPlace[areaId.length - 1] = [];
    }
    index = areaId.indexOf(data[i].AreaID);
    dataInPlace[index].push(data[i]);
  }
  return {
    type: "RECEIVE_INFORMATION",
    memberNumber: data.length,
    dataInPlace,
  };
};

// export const initFcm = () => {
//     return (dispatch, getState) => {
//         messaging.requestPermission()
//         .then(()=>{
//             console.log('Have Permission');
//             return messaging.getToken()
//         })
//         .then((fcmToken)=>{
//             console.log('fcmToken: ', fcmToken);

//             let apikey = getApikey()
//             fetch('https://i-daka.com:5425/api/FCM/Subscribe',{
//                 method: 'POST',
//                 body: JSON.stringify({
//                     Token: fcmToken,
//                     Topic: 'test',
//                 }),
//                 headers: {
//                     'content-type': 'application/json',
//                     'apikey': apikey
//                 }
//             })
//             .then((res)=>res.json())
//             .then((json)=>{
//                 console.log(json);
//                 fcm_onMessage()
//                 test_fcm()
//             })
//         })
//         .catch((err)=>{
//             console.log(err);
//         })

//     }
// }

export const receiveFcm = (data) => {
  return {
    type: "RECEIVE_FCM",
    data,
  };
};

export const test_fcm = () => {
  let apikey = getApikey();
  fetch("https://i-daka.com:5425/api/TvBoxWeb/Test/PushNotify", {
    method: "POST",
    body: JSON.stringify({
      Mission: "test",
    }),
    headers: {
      "content-type": "application/json",
      apikey: apikey,
    },
  })
    .then((res) => res.json())
    .then((json) => {
      receiveFcm(json);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logout = () => {
  return (dispatch, getState) => {
    fetch("https://i-daka.com:5425/api/TvBoxWeb/Logout", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        apikey: getState().user.apikey,
        authorization: getState().user.token,
      },
    }).then((res) => {
      if (res.status == 200) {
        dispatch({
          type: "LOGOUT",
        });
      }
    });
  };
};
