import InformationComponent from "../components/informationComponent";
import { connect } from "react-redux";
import { queryInformation, queryInformation_test } from "../actions";

const mapStateToProps = (state, ownProps) => {
  return {
    information: state.information,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    queryInformation: () => {
      dispatch(queryInformation());
    },
  };
};

const InformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationComponent);

export default InformationContainer;
