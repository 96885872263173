import React from "react";
import "./LoginPage.css";
import Header from "../containers/HeaderContainer.js";
import Alert from "@material-ui/lab/Alert";

import "../css/all.css";
import "../css/bootstrap.min.css";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Account: "",
      Password: "",
      JwtToken: "",
      testJwtToken: "",
      apikey: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    this.props.checkLogin();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    let { Account, Password } = this.state;
    this.props.handleLogin(Account, Password);
  }

  render() {
    let { Account, Password } = this.state;
    return (
      <>
        <Header />

        <div className="container border-top">
          <main role="main">
            <div className="row">
              <div className="col-sm-8 mx-auto">
                <h4>手機號</h4>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={Account}
                    name="Account"
                    onChange={this.handleChange}
                    placeholder="請輸入手機號"
                  />
                </div>
                <h4>密碼</h4>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    value={Password}
                    name="Password"
                    onChange={this.handleChange}
                    placeholder="請輸入密碼"
                  />
                </div>

                {this.props.login.error ? (
                  <div className="alert alert-danger" role="alert">
                    {this.props.login.message}
                  </div>
                ) : (
                  ""
                )}
                {this.props.login.success ? (
                  <div className="alert alert-danger" role="alert">
                    {this.props.login.message}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-sm-8 mx-auto ">
                <button
                  type="button"
                  className="btn-allwd addbottom"
                  onClick={this.handleLogin}
                >
                  <h6>登入</h6>
                </button>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default LoginComponent;
