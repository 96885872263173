import App from '../components/App.js'
import {connect} from 'react-redux'
import {receiveFcm, initFcm} from '../actions'

const mapStateToProps = (state, ownProps) => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        receiveFcm: () => {
            dispatch(receiveFcm())
        },
        // initFcm: ()=>{
        //     dispatch(initFcm())
        // }
    }
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

export default AppContainer