import React from "react";
import LoginPage from "../containers/LoginContainer.js";
import InformationContainer from "../containers/informationContainer.js";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fdce50",
    },
    secondary: {
      main: "#000000",
    },
  },
});

class App extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {this.props.login.success ? <InformationContainer /> : <LoginPage />}
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
