import React from 'react'
import './header.css'
import '../css/all.css';
import "../css/bootstrap.min.css"

class HeaderComponent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            company: '璽樂科技'
        }
    }
    render(){        
        return (
            <nav  id="navbar" className="navbar navbar-expand-md navbar-dark bg-dark">
                <img src="./logo_brand.png"></img>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
        )
    }
}

export default HeaderComponent