import { combineReducers } from "redux";
const user = (
  state = {
    apikey: "",
    token: "",
  },
  action
) => {
  switch (action.type) {
    case "RECEIVE_JWTTOKEN":
      return Object.assign({}, state, {
        token: action.token,
        apikey: action.apikey,
      });
    case "LOGOUT":
      return Object.assign({}, state, {
        apikey: "",
        token: "",
      });
    default:
      return state;
  }
};

const login = (
  state = {
    message: "",
    error: false,
    success: false,
  },
  action
) => {
  switch (action.type) {
    case "LOGIN_FAIL":
      return Object.assign({}, state, {
        message: action.message,
        error: true,
        success: false,
      });
    case "LOGIN_SUCCESS":
      return Object.assign({}, state, {
        message: action.message,
        error: false,
        success: true,
      });
    case "LOGOUT":
      return Object.assign({}, state, {
        message: "",
        error: false,
        success: false,
      });
    default:
      return state;
  }
};

const information = (
  state = {
    company: "",
    memberNumber: "",
    location: "",
    members: [],
  },
  action
) => {
  switch (action.type) {
    case "RECEIVE_INFORMATION":
      return Object.assign({}, state, {
        memberNumber: action.memberNumber,
        members: action.dataInPlace,
      });
    default:
      return state;
  }
};

const fcm = (
  state = {
    datail: "",
  },
  action
) => {
  switch (action.type) {
    case "RECEIVE_FCM":
      return Object.assign({}, state, {
        datail: action.datail,
      });
    default:
      return state;
  }
};

const todoApp = combineReducers({
  user,
  login,
  information,
  fcm,
});

export default todoApp;
