import HeaderComponent from '../components/HeaderComponent'
import {connect} from 'react-redux'
import {logout} from '../actions'
const mapStateToProps = (state, ownProps) => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => dispatch(logout())
    }
}
const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent)

export default HeaderContainer