import React from "react";
import "./informationComponent.css";
import "../css/bootstrap.min.css";

class InformationComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.queryInformation();
    window.refresh = this.refresh;
  }
  refresh() {
    window.location.reload();
  }
  render() {
    console.log("this.props.information", this.props.information);
    return (
      <div className="container-fluid m-0 p-0">
        <div className="totalNumber h1 m-0 p-2">
          目前 {this.props.information.memberNumber} 人
        </div>
        {this.props.information.members.map((place, index) => {
          return (
            <div key={index}>
              <div className="location h2 p-2">{place[0].AreaName}</div>
              <div className="members row mb-3">
                {place.map((member, index) => {
                  return (
                    <div key={index} className="col-2">
                      <div className="image-cropper">
                        <img
                          className="member_img"
                          alt="avatar"
                          src={member.PhotoLink}
                        ></img>
                      </div>
                      <div>{member.Name}</div>
                      <div>{member.Phone}</div>
                      <div>{member.CompanyName}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default InformationComponent;
